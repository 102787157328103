.renewal-info{
	.contents-area{
		border: 1px solid $line-gray;
		padding: 30px 30px 20px;
		color: #6b6b6b;
		font-size: 14px;
		@include screen(custom, max, $max-sm) {
			padding: 15px 20px;
		}
		.sub-tit{
			color:$color-black;
			@include font-family($font-semibold);
			font-size:20px;
			margin-bottom: 8px;
			line-height: 28px;
			@include screen(custom, max, $max-sm) {
				text-align: center;
				line-height: 24px;
				font-size:18px;
			}
		}
		.info-txt{
			padding-bottom: 15px;
			margin-bottom: 15px;
			border-bottom: 1px solid $line-gray;
			@include screen(custom, max, $max-sm) {
				text-align: center;
			}
			strong{
				color: $color-carmine;
				text-decoration: underline;
			}
		}
		.desc{
			p{margin-bottom: 8px;}
			p + p{margin-bottom: 0px;}
		}
	}
	.txt{padding-top: 13px;
		@include screen(custom, max, $max-sm) {font-size: 14px;}
	}
}