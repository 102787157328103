.result-other-box{
	position:relative;
	z-index:1;
	width:calc(100% - 327px);
	float:right;
	[dir="rtl"] & {
		@include screen(custom, min, (850 + 1)){
			float:left;
		}
	}
	@include screen(custom, max, 850) {
		float:none;
		width:100%;
	}
	// @include screen(custom, max, $max-xs) {
	// 	float:none;
	// 	width:100%;
	// }
	.sort-box {
		position:relative;
		min-height:65px;
		background:$bg-whitesmoke;
		padding:15px 0 15px 24px;
		[dir="rtl"] & {
			text-align: right;
			@include screen(custom, min, (850 + 1)){
				padding:15px 24px 15px 0;
			}
		}
		@include screen(custom, max, 850) {
			min-height:55px;
			padding:0 24px 30px;
			border-bottom:1px solid $line-lightgray;
			background:none;
		}
		/* LGEGMC-574 20200901 add */
		.sort-label{
			display: inline-block;
			vertical-align: middle;
			margin: 0 7px 0 0;
			@include font-family($font-regular);
			font-size: 16px;
			color: $color-nightrider;
			@include screen(custom, max, $max-sm) {
				display: block;
				margin: 0 0 10px 0;
				font-size: 14px;
			}
			[dir="rtl"] & {
				margin: 0 0 0 7px;
				@include screen(custom, max, $max-sm) {
					margin: 0 0 10px 0;
				}
			}
		}
		/* //LGEGMC-574 20200901 add */
		.sort-select{
			/* LGEGMC-574 20200901 add */
			display: inline-block;
			vertical-align: middle;
			/* //LGEGMC-574 20200901 add */
			width:193px;
			@include screen(custom, max, $max-md) {
				width:155px;
			}
			font-size:14px;
			height:34px;
			select{
				height:34px;
				font-size:14px;
				padding-top:0;
				padding-bottom:0;
				@include screen(custom, max, $max-sm) {
					font-size:16px; // Prevent ios auto zoom
				}
			}
			@include screen(custom, max, 850) {
				width:100%;
			}
			.chosen-container{
				font-size:14px;
				&.chosen-container-active {
					.chosen-single {
						padding-top:7px;
					}
				}
			}
			.chosen-single{
				height:34px;
				padding-top:8px;
				padding-bottom:0;
			}
		}
		.result-info{
			position:absolute;
			top:19px;
			right:25px;
			[dir="rtl"] & {
				text-align:right;
				@include screen(custom, min, (850 + 1)){
					right:inherit;
					left:25px;
				}
			}
			@include screen(custom, max, 850) {
				position:relative;
				top:12px;
				right:auto;
				&:only-child,
				&:first-child {
					margin-top:-25px;
				}
			}
			.info {
				/* 20191111 : LGEUS-11779 add */
				.total2{
					display: none;
				}
				/* //20191111 : LGEUS-11779 add */
				font-size:16px;
				color:$color-dimgray;
				@include screen(custom, max, 850) {
					font-size:14px;
				}
				strong {
					@include font-family($font-semibold);
					color:$color-carmine;
				}
			}
			.link-text {
				display:inline-block;
				font-size:14px;
				margin-left:16px;
				[dir="rtl"] & {
					margin-left:0;
					margin-right:16px;
				}
				@include screen(custom, max, 850) {
					position:absolute;
					top:2px;
					right:0;
					[dir="rtl"] & {
						right:auto;
						left:0;
					}
				}
			}
		}
	}
	/* 20191111 : LGEUS-11779 add */
	.apply-filters{
		margin-top: 20px;
		[dir="rtl"] & {text-align:right;}
		@include screen(custom, max, 850) {
			padding: 5px 24px 25px;
		}
		span {
			display: inline-block;
			vertical-align: middle;
			// 20200311 START 박지영 : GPC0007에서 apply-filters 영역 여러줄 가능하도록 수정
			min-height: 26px;
			// 20200311 END
			font-size: 14px;
			line-height: 17px;
			color: $color-dimgray;
			border: 1px solid #d9d9d9;
			border-radius: 3px;
			margin-bottom: 5px;
			margin-right: 5px;
			padding: 3px 8px 3px 10px;
		[dir="rtl"] & {
			margin-right: 0;
			margin-left: 5px;
			padding: 3px 10px 3px 8px;
		}
		a {
			display: inline-block;
			vertical-align: bottom;
			width: 8px;
			height: 8px;
			background: url('/lg5-common-gp/images/common/icons/modal-close.svg') no-repeat 100% 0px;
			background-size: 8px 8px;
			position: relative;
			top: -4px;
			color: $color-dimgray;
			margin-left: 5px;
			[dir="rtl"] & {
				margin-left: 0;
				margin-right: 5px;
			}
		}

		}
		.link-text{
			display:none;
			&.active{
				display:inline-block;
				@include screen(custom, min, (850 + 1)) {
					margin-left:11px;
					[dir="rtl"] & {
						margin-left:0;
						margin-right:11px;
					}
				}
				@include screen(custom, max, 850) {
					width:100%;
					margin-top:20px;
				}
			}
		}
	}
	/* // 20191111 : LGEUS-11779 add */
	.banner-box {
		position:relative;
		overflow:hidden;
		height:320px;
		margin-top:20px;
		@include screen(custom, min, $min-md) {
			&.text-black {
				color:$color-black;
				background-color: $bg-white;
				.text-block {
					.btn-area {
						.btn {
							color:$color-black !important;
						}
						.btn-outline-light {
							border-color:$line-black !important;
							color:$color-black !important;
						}
					}
				}
			}
			&.text-white {
				color:$color-white;
				background-color: $bg-gray;
				.btn-area {
					.btn {
						color:$color-white !important;
					}
					.btn-outline-light {
						border-color:$color-white !important;
					}
				}
			}
		}
		@include screen(custom, max, $max-sm) {
			height:auto;		
			// 20200416 START 이상현 - 제품의 flag 디자인 변경 반영함.
			padding-bottom:35px;
			// 20200416 END
			margin:26px 24px 0 24px;
			border-bottom:1px solid $line-lightgray;
		} 
		.banner-image {
			position:absolute;
			z-index:1;
			top:0;
			left:0;
			width:100%;
			img {
				@include screen(custom, max, $max-sm) {
					width:100%;
				}
			}
			@include screen(custom, max, $max-sm) {
				position:relative;
				margin-bottom:18px;
			} 
		}
		&.align-left,
		&.align-right {
			.text-block {
				left:0;
				width:(340px+100px);
				@include screen(custom, min, $min-md) {
					[dir="rtl"] & {text-align:right;}
				}
				@include screen(custom, max, $max-sm) {
					left:auto;
					width:100%;
				}
			}
		}
		&.align-right {
			.text-block {
				left:auto;
				right:0;
				text-align:left;
				@include screen(custom, max, $max-sm) {
					right:auto;
				}
			}
			.banner-image {
				@extend %clearfix;
				img.pc {float:right;}
			}
		}
		&.align-center {
			.text-block {
				left:50%;
				text-align:center;
				transform:translate(-50%,-50%);
				width:(460px+100px);
				@include screen(custom, max, $max-sm) {
					width:100%;
					left:auto;
					transform:translate(0, 0);
				}
			}
			.banner-image {
				img.pc {
					position:absolute;
					top:0;
					left:50%;
					transform:translate(-50%, 0);
				}
			}
		}
		.text-block{
			position:absolute;
			z-index:1;
			top:50%;
			width:100%;
			transform:translate(0,-50%);
			padding:0 50px;
			@include screen(custom, max, $max-sm) {
				position:relative;
				top:auto;
				left:auto;
				width:100%;
				padding:0;
				transform:translate(0,0);
				text-align:center;
			} 
			.text-area{
				// color:$color-white;
				@include screen(custom, max, $max-sm) {
					color:$color-black;
					br {display:none;}
				}
				.text{
					@include font-family($font-semibold);
					font-size:32px;
					line-height:36px;
					@include screen(custom, max, $max-sm) {
						font-size:24px;
						line-height:28px;
					}
				}
				.sub-text{
					margin-top:12px;
					font-size:16px;
					line-height:24px;
					@include screen(custom, max, $max-sm) {
						margin-top:8px;
					}
				}
			}
			.btn-area{
				// 20200416 START 이상현 - 제품의 flag 디자인 변경 반영함.
				margin-top:18px;
				// 20200416 END
				.btn {
					color:$color-white !important;
					@include screen(custom, max, $max-sm) {
						width:100%;
						color:$color-black !important;
					}
				}
				.btn-outline-light{
					@include screen(custom, max, $max-sm) {
						border-color:$line-black !important;
					}
				}
			}
		}
	}
	.product-list-box{
		// overflow:hidden;
		position:relative;
		@include screen(custom, max, $max-xs) {
			padding:0 2px 0 2px;
		}
		.list-box{
			@extend %clearfix;
			position:relative;
			margin:0;
			font-size:0; // do not remove
			[dir="rtl"] & {text-align:right;}
			@include screen(custom, max, $max-sm) {
				padding:1px 24px 22px;
			}
			@include screen(custom, max, 374) {
				padding-left:14px;
				padding-right:14px;
			}
			> li {
				position: relative;
				margin-top:-1px;
				width:34.17%;
				height:auto;
				text-align:center;
				display:inline-block;
				vertical-align:top;
				font-size:1rem; // do not remove
				&:nth-child(3n){
					padding-right:0;
				}
				[dir="rtl"] & {
					&:nth-child(3n){
						padding-left:0;
						padding-right:12px;
					}
				}
				@include screen(custom, min, $min-md){
					//padding:29px 12px 25px;
					padding:29px 12px 38px;
				}
				@include screen(custom, min, 1070) {
					//margin-bottom:-4px;
					&:nth-child(3n){
						width:31.66%;
					}
					&:nth-child(3n+1):before{
						content:"";
						display:block;
						width:calc(100% * 3 - 24px);
						height:1px;
						background-color: $line-lightgray;
						position: absolute;
						top:0; left:0;
						[dir="rtl"] & {
							left:auto;
							right:0;
						}
					}
				}
				@include screen(custom, max, 1069) {
					width:50%;
					padding-left:18px;
					padding-right:18px;
					//height:718px;
					//margin-bottom: 24px;
					&:nth-child(odd) {
						clear:both;
					}
					&:nth-child(odd):before{
						content:"";
						display:block;
						width:200%;
						height:1px;
						background-color: $line-lightgray;
						position: absolute;
						top:0; left:0;
						[dir="rtl"] & {
							left:auto;
							right:0;
						}
					}
					&:nth-child(1):before{display:none;}
				}
				@include screen(custom, max, $max-md) {
					//margin-bottom: 36px;
				}
				@include screen(custom, max, $max-sm) {
					//height: 635px;
					// 20200416 START 이상현 - 제품의 flag 디자인 변경 반영함.
					padding:20px 0 26px 0px;
					// 20200416 END
					//margin-bottom: 0;
					&:nth-child(odd){padding-right:12px;};
					&:nth-child(even){padding-left:12px;};
					[dir="rtl"] & {
						&:nth-child(odd){
							padding-right:0;
							padding-left:12px;
						};
						&:nth-child(even){
							padding-left:0;
							padding-right:12px;
						};
					}
				}
				@include screen(custom, min, (850 + 1)) {
					&:nth-child(1):before{
						display:none;
					}
				}
				.item {
					position:relative;
					// text-align:center;
					.visual {
						position:relative;
						width:200px;
						height:200px;
						display:inline-block;
						@include screen(custom, max, $max-sm) {
							width:130px;
							height:130px;
						}
						img{
							width:100%;
						}
					}
					.tag-content {
						overflow: hidden;
						padding-top:20px;
						height:40px;
						margin:0 auto 20px;
						span {
							display:inline-block;
							vertical-align:top;
							height:18px;
							padding:0 10px;
							border-radius:3px;
							margin:0 1px 6px;	
							font-size:12px;
							line-height:18px;
							color:$color-white;
							@include font-family($font-semibold);
							text-transform:uppercase;
							background:linear-gradient(-90deg, #333333 15%, #464646 85%);
						}
						@include screen(custom, max, $max-sm) {
							padding-top:10px;
							height:30px;
							margin-bottom:12px;
							span {
								height:20px;
								line-height:20px;
							}
						}
					}
					.model-group {
						/* LGECZ-10 : 20200529 add  */
						margin:21px 15px 0;
						@include screen (custom, max, $min-md) {
							margin:21px -41px 0;
						}							
						@include screen (custom, max, $max-sm) {
							margin:21px -3px 0;
						}
						/* //LGECZ-10 : 20200529 add */
					}
					a.visual + .products-info {
						margin-top:25px;
						@include screen(custom, max, $max-sm) {
							margin-top:23px;
						}
					}
					.products-info {
						// text-align:center;
						margin-top:10px;
						.model-name {
							font-size:18px;
							line-height:22px;
							color:$color-nightrider;
							width:95%;
							height:22px * 3;
							margin:auto;
							overflow:hidden;
							text-overflow: ellipsis;
							display:block;
							display:-webkit-box;
							-webkit-line-clamp:3;
							-webkit-box-orient:vertical;
							// 20200608 START 박지영 - RTL 에서 괄호로 끝나는 경우 깨지는 현상 수정
							[dir="rtl"] & {
								display:block;
							}
							// 20200608 END
							@include screen(custom, max, $max-sm) {
								font-size:14px;
								line-height:18px;
								height:18px * 4;
								-webkit-line-clamp:4;
							}
							a {
								color:$color-nightrider;
							}
						}
						.sku {
							margin:10px 0 0;
							min-height:18px;
							font-size:14px;
							line-height:18px;
							color:$color-dimgray;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
						}
						.rating {
							max-width:210px;
							margin:6px auto 0;
							height:30px;
							@include screen(custom, max, $max-sm) {
								height:26px;
								margin:0 auto;
							}
							.bv_stars_component_container {
								line-height: 11px!important;
							}
						}
						.price-area.total{
							margin:0 auto;
						}
						/* LGEGMC-177 : 20200521 add */
						.file-list {
							height: 30px;
							@include screen(custom, max, $max-sm) {
								min-height: 30px;
								height: 100%;
							}
							a {
								display: inline-block;
								&:hover {text-decoration: none;}					
								
								.fiche {
									display: inline-block;
									vertical-align: top;
									font-size:12px;
									max-width: 150px;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									@include font-family($font-regular);
									color:$color-dimgray;
									line-height: 1.7em;
									&.type-product {
										max-width: 210px;
									}
									@include screen(custom, max, $max-sm) {
										max-width: 100%;									
									}
								}
								.label {
									display: inline-block;
									vertical-align: top;
									font-size: 0;	
									// width: 42px;
									height: 36px;
									@include screen(custom, max, $max-sm) {
										height: 26px;
									}
									> img {
										width: 100%;
										height: 100%;
									}
								}
							}
						}
						/* //LGEGMC-177 : 20200521 add */
						.promotion-text {
							font-size:14px;
							line-height:22px;
							color:$color-nightrider;
							height:34px;
							padding-top:4px;
							margin:0 auto;
							max-width:210px;
							// text-align:center;
							overflow: hidden;
							text-overflow:ellipsis;
							white-space:nowrap;
							@include font-family($font-bold);
							@include screen(custom, max, $max-md) {
								height: 35px;
								margin-top:-7px;
							}
						}
						.button {
							margin:auto;
							max-width:210px;
							min-height:80px;
							a {
								width:100%;
								margin-top:0;
								&:first-child {margin-top:0;}
								display:none;
								&.active {display:inline-block;}
								@include screen(custom, max, $max-sm) {
									padding-left:0;
									padding-right:0;
								}
							}
							// PJTOBS 20200703 Start
							a + a.where-to-buy.active,
							a + a.find-a-dealer.active {
								margin-top:45px;
							}
							a.re-stock-alert.active + a + a.where-to-buy.active,
							a.active + a.where-to-buy.active,
							a.active + a.find-a-dealer.active {
								margin-top:10px;
							}
							// PJTOBS 20200703 End
						}
					}
					.box-impInfo{
						font-size: 13px;
						min-height:58px;
						padding: 10px 10px;
						background: #f8f8f8;
						display: flex;
						justify-content: center;
						align-items: center;
						max-width: 210px;
						margin: 0 auto;
						p{
							margin: 0 auto;
							line-height: 1.4em;
							max-height: 2.8em;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}
					}
					.rebate-box{
						margin-top:8px;
						@include screen(custom, max, $max-sm) {
							margin-top:6px;
						}
					}
				}
				.price-wt-box{
					display: flex;
					justify-content: center;
					align-items: center;
					flex-flow: column;
					height: 50px;
					.member-text{
						display:none;
					}
					&.has-topInfo{
						height:100px;
					}
					&.has-member{
						height: 90px;
						.member-text{
							display:block !important;
						}
						@include screen(custom, max, $max-sm) {
							height:84px;
						}	
					}
					&.has-topInfo.has-member{
						height: 150px;
						@include screen(custom, max, $max-sm) {
							height:140px;
						}	
					}
					&.has-lowPrice{
						height: 100px;
						.lowest-price{
							display:block !important;
						}
						&.has-topInfo{
							height: 140px;
							@include screen(custom, max, $max-sm) {
								height: 145px;
							}
						}
						&.has-member{
							height: 125px;
							@include screen(custom, max, $max-sm) {
								height: 120px;
							}
						}
						&.has-topInfo.has-member{
							height: 165px; 
							@include screen(custom, max, $max-sm) {
								height: 175px;
							}
						}			
					}
				}
			}
		}	
		.pagination{
			margin-bottom:50px;
			@include screen(custom, max, $max-sm) {
				//margin-top:0;
			}

			a, button {
				&:disabled {
					display: none;
				}
			}
		}
		// more button custom
		.expander {
			padding:0 24px 40px;
			@include screen(custom, max, $max-sm) {
				padding:0 24px 40px;
			}
			@include screen(custom, max, 374) {
				padding:0 14px 40px;
			}
		}
		// no-content
		.no-content {
			display: none;
		}
	}
}
// no filter
.no-filter{
	.result-other-box{
		float:none;
		width:100%;
		.product-list-box .list-box > li {
			border-bottom:none;
			@include screen(custom, min, 1070){
				width:25%;
				&:nth-child(3n) {
					padding-left:12px;
					padding-right:12px;
					[dir="rtl"] & {
						padding-left:12px;
						padding-right:12px;
					}
				}
				&:nth-child(3n+1):before{
					display:none;
				}
				&:nth-child(4n+1):not(:first-child):before{
					content:"";
					display:block;
					width:400%;
					height:1px;
					background-color: $line-lightgray;
					position: absolute;
					top:0; left:0;
					[dir="rtl"] & {
						left:auto;
						right:0;
					}
				}
			}
		}
	}
}