#modal_pdp_detail_gallery {
	z-index: 25001; // [LGEIS-916]
	&.loaded-iframe{
		.modal-body{
			padding: 0 80px 20px 0;
			min-height:640px;
			height:auto;
			.modal-messagecode{
				display:block;
				@include screen(custom, max, $max-sm) {
					display:none !important;
				}
			}
		}
	}
	.modal-body{
		.modal-messagecode{
			display:none;
		}
	}
	@include screen(custom, max, 1200) { // LGEGMC-3931 : width 1200 수정
		&:before {
			display:none;
		}
	}
}

.modal {
	.modal-dialog {
		max-width:100%;
		&.modal-pdp-gallery {
			// LGEGMC-3931 : width 1200 수정
			width:1200px;
			margin-left:-600px;
			@include screen(custom, max, 1200) {
				width:100%;
				top:0;left:0;
				// 20200601 START 박지영 - 레이어 팝업 여백 오류 수정
				margin:0;
				// 20200601 END
			}
			iframe {
				width:100%;height:100%;border:0;
			}
			.modal-body {
				@extend %clearfix;
				touch-action: manipulation;
				position:relative;
				height:640px;
				@include screen(custom, min, $min-md) {
					padding:0 80px 0 0;
					[dir="rtl"] & {padding:0 0 0 80px;}
				}
				@include screen(custom, max, $max-sm) {
					//height:667px;
					height:617px;
					padding:90px 0 0 0;
				}
				&.append-target {
					padding-top:65px;
					padding-bottom:30px;
					@include screen(custom, max, $max-sm) {
						padding:50px 0 0;
					}
				}
				.default{
					position:relative;
					height:100%;
					@include screen(custom, min, $min-md) {
						width:calc(100% - 220px); // LGEGMC-3931
						float:right;
						[dir="rtl"] & {float:left;}
					}
					@include screen(custom, max, $max-sm) {
						width:100%;
						height:auto;
					}
					.slick-list {
						overflow:hidden;
						height:640px;
						width: 100%;
						max-width: 830px; // LGEGMC-3931
						@include screen(custom, max, $max-sm) {
							//height:445px;
							height:395px;
						}
					}
					.slick-track {
						overflow:hidden;
						height:100%;
					}
					.item {
						float:left;
						[dir="rtl"] & {float:right;}
						width:848px;
						height:100%;
						text-align:center;
					}
					.slick-slide{
						&:focus{
							outline: none;
						}
					}
					.zoom-area {
						width:100%;
						height:100%;
						cursor: url('/lg5-common-gp/images/components/zoomin_cursor.png'), url('/lg5-common-gp/images/components/zoomin_cursor.cur'), pointer;
						&.zoomout {
							cursor: url('/lg5-common-gp/images/components/zoomout_cursor.png'), url('/lg5-common-gp/images/components/zoomout_cursor.cur'), pointer;
						}
					}
					img { 
						position: absolute;
						top:0;bottom:0;left:0;right:0;
						margin: auto;
						max-width:100%;
						max-height:100%;
						transition: transform 200ms ease-in-out 0s;
						&.pc{
							display:block;
						}
						&.mobile{
							display:none;
						}
						@include screen(custom, max, $max-sm) {	
							transition: none;
							&.pc{
								display:none;	
							}
							&.mobile{
								display:block;
								margin: 0 auto;
							}
						}
					}
					// iframe contents (youtube, brightcove, 360view)
					.iframe-area {
						width: 100%;
						height: 100%;
					}
					/* LGEGMC-849 20201103 add */
					.viewVr {
						display: flex;
						align-items: center;
						.iframe-area {
							height: 0;
							position: relative;
							padding-bottom: 56.25%; //16:9 ratio
							iframe {
								position: absolute;
								top: 0;
								left: 0;
							}
						}
					}
					/* //LGEGMC-849 20201103 add */
					/*LGEGMC-1632 start*/
					&.viewAR{width:100%}
					/*LGEGMC-1632 end*/
				}
				.gallery-thumbnail {
					float:left;
					[dir="rtl"] & {float:right;}
					width:156px;
					padding-top:34px;
					@include screen(custom, max, $max-sm) {
						display:none;
					}
					ul {
						width:60px;
						margin:0;
						vertical-align:top;
						display:flex;
						flex-wrap:wrap;
						flex-direction:column;
						height:580px;
					}
					li {
						display:inline-block;
						border:1px solid $line-gray;
						margin:0 2px 4px;
						a {
							display:block;
							width:58px;height:58px;
							text-align:center;
						}
						img {
							max-width:100%;
						}
						&:first-child {margin-top:0;}
						&:hover,
						&.active {
							border-color:$line-carmine;
						}
						&.view {
							border-color:transparent;
							a {
								background:url('/lg5-common-gp/images/components/gallery-360vr.svg') no-repeat 0 0;
								overflow:hidden;
								white-space:nowrap;
								text-indent:200%;
								[dir="rtl"] & {text-indent:-1000%;}
							}
							&:hover a,
							&.active a {
								border-color:transparent;
								background-image: url(/lg5-common-gp/images/components/gallery-360vr-carmine.svg)
							}
						}
						/* LGEGMC-849 20201102 add */
						&.viewVr {
							border-color:transparent;
							a {
								background:url('/lg5-common-gp/images/components/gallery-vr.svg') no-repeat 0 0;
								overflow:hidden;
								white-space:nowrap;
								text-indent:200%;
								[dir="rtl"] & {text-indent:-1000%;}
							}
							&:hover a,
							&.active a {
								border-color:transparent;
								background-image: url('/lg5-common-gp/images/components/gallery-vr-carmine.svg')
							}
						}
						/* //LGEGMC-849 20201102 add */
						&.video {
							a:before {
								width:32px;height:32px;
								margin:-16px 0 0 -16px;
								[dir="rtl"] & {margin:-16px -16px 0 0;}
							}
						}
					}
				}
				.thumbnail{
					overflow:hidden;
					float:left;
					width:220px; // LGEGMC-3931
					@include screen(custom, min, $min-md) {
						ul {
							display:flex;
							flex-direction:column;
							flex-wrap:wrap;
							height:640px;
							padding:34px 12px 0 16px;
							align-content: flex-start; // LGEGMC-3931
							[dir="rtl"] & {padding:34px 16px 0 12px;}
						}
					}
					@include screen(custom, max, $max-sm) {
						float:none;
						position:relative;
						text-align:center;
						width:330px;
						padding:0 30px;
						margin:37px auto 0 auto;
						@include screen(custom, max, 320) {
							width:320px;
							padding:0 25px;
						}
					}
					.slick-prev{
						border:none;
						width:20px;
						height:20px;
						background:url(/lg5-common-gp/images/common/icons/arrow-up-black.svg) no-repeat;
						margin-left:20px;
						text-indent:-9999em;
						[dir="rtl"] & {
							text-indent:9999em;
							transform: rotate(180deg);
						}
						@include screen(custom, max, $max-sm) {
							margin-top:0;
							margin-left:0;
							background:url(/lg5-common-gp/images/common/icons/arrow-left-black.svg) no-repeat;
							position:absolute;
							top:35px;
							left:-4px;
							[dir="rtl"] & {
								top:25px;
								left:auto;
								right:-4px;
							}
							@include screen(custom, max, 320) {
								left:0;
								[dir="rtl"] & {
									left:auto;
									right:0;
								}
							}
						}
						&.slick-disabled{
							background:url(/lg5-common-gp/images/common/icons/arrow-up-gray.svg) no-repeat;
							@include screen(custom, max, $max-sm) {
								background:url(/lg5-common-gp/images/common/icons/arrow-left.svg) no-repeat;
							}
						}	
						
					}
					.slick-next{
						border:none;
						width:20px;
						height:20px;
						margin-top:4px;
						background:url(/lg5-common-gp/images/common/icons/arrow-down-black.svg) no-repeat;
						margin-left:20px;
						text-indent:-9999em;
						[dir="rtl"] & {
							margin-left:0;
							margin-right:20px;
							text-indent:9999em;
							transform: rotate(180deg);
						}
						@include screen(custom, max, $max-sm) {
							margin-top:0;
							margin-left:0;
							background:url(/lg5-common-gp/images/common/icons/arrow-right-black.svg) no-repeat;
							position:absolute;
							top:35px;
							right:-4px;
							[dir="rtl"] & {
								top:25px;
								right:auto;
								left:-4px;
							}
							@include screen(custom, max, 320) {
								right:0;
								[dir="rtl"] & {
									right:auto;
									left:0;
								}
							}
						}
						&.slick-disabled{
							background:url(/lg5-common-gp/images/common/icons/arrow-down-gray.svg) no-repeat;
							@include screen(custom, max, $max-sm) {
								background:url(/lg5-common-gp/images/common/icons/arrow-right.svg) no-repeat;
							}
						}	
					}
					.slick-list {
						overflow:hidden;
						margin-top:6px;
						width: 60px;
						@include screen(custom, max, $max-sm) {
							width:279px;
						}
					}
					.item {
						// WA-GPC0009-04 : 추가 시작
						a {
							position:relative;
							display: block;
							width: 58px;
							height: 58px;
							line-height:58px - 2px;
						}
						// WA-GPC0009-04 : 추가 끝
						position:relative;
						width:60px;
						height:60px;
						margin:0 4px 4px 0; // LGEGMC-3931
						border:1px solid $line-gray;
						text-align:center;
						cursor:pointer;
						overflow:hidden;
						// LGEGMC-3931 start
						[dir="rtl"] & {
							margin:0 0 4px 4px; 
						}
						// LGEGMC-3931 end
						@include screen(custom, max, $max-sm) {
							float:left;
							margin:0 10px 0 0;
							[dir="rtl"] & {
								float:right;
								margin:0 0 0 10px;
							}
							&.slick-current {
								border-color:$line-carmine;
								a {
									&:after {
										content:'';
										display:block;
										border:1px solid $line-carmine;
										width:58px;
										height:58px;
										position:absolute;
										top:0;
										left:0;
										[dir="rtl"] & {
											left:auto;
											right:0;
										}
									}
								}
							}
						}
						@include screen(custom, min, $min-md) {
							&:hover {
								width:62px;
								height:62px;
								border-color:$line-carmine;
								border-width:2px;
								// LGEGMC-3931 start
								margin:-1px 3px 3px -1px;
								[dir="rtl"] & {
									margin:-1px -1px 3px 3px;
								}
								// LGEGMC-3931 end
							}
							&.slick-current {
								border-color:$line-carmine;
								width:62px;
								height:62px;
								border-color:$line-carmine;
								border-width:2px;
								// LGEGMC-3931 start
								margin:-1px 3px 3px -1px;
								[dir="rtl"] & {
									margin:-1px -1px 3px 3px;
								}
								// LGEGMC-3931 end
							}
						}
						&.video:before {
							content: '';
							display: inline-block;
							position: absolute;
							left: 50%;
							top: 50%;
							width: 50%;
							height: 50%;
							margin: -15px 0 0 -15px;
							background: url(/lg5-common-gp/images/components/btn-play.svg) no-repeat 0 0;
							background-size: 100% 100%;
							z-index:1;
						}
						// WA-GPC0009-04 : 추가 시작
						&.video {
							a:before {
								display: none;
							}
						}
						// WA-GPC0009-04 : 추가 끝
						&.hidden {
							display: none;
						}
						&.view {
							background: url(/lg5-common-gp/images/components/gallery-360vr.svg) no-repeat 0 0;
							background-size: 100% 100%;
							border-color:transparent;
							&:hover,
							&.slick-current {
								border-color:transparent;
								background-image: url(/lg5-common-gp/images/components/gallery-360vr-carmine.svg)
							}
						}
						/* LGEGMC-849 20201102 add */
						&.viewVr {
							background: url('/lg5-common-gp/images/components/gallery-vr.svg') no-repeat 0 0;
							background-size: 100% 100%;
							border-color:transparent;
							&:hover,
							&.slick-current {
								border-color:transparent;
								background-image: url('/lg5-common-gp/images/components/gallery-vr-carmine.svg')
							}
						}
						/* //LGEGMC-849 20201102 add */
						/* LGEGMC-1632 add */
						&.viewAr {
							background: url('/lg5-common-gp/images/components/gallery-ar.svg') no-repeat 0 0;
							background-size: 100% 100%;
							border-color:transparent;
							&:hover,
							&.slick-current {
								border-color:transparent;
								background-image: url('/lg5-common-gp/images/components/gallery-ar-carmine.svg')
							}
						}
						/* //LGEGMC-1632 add */
						// LGEGMC-3178 start
						&.view360_v2 {
							background: url('/lg5-common-gp/images/components/gallery-3D.svg') no-repeat 0 0;
							background-size: 100% 100%;
							border-color: transparent;
							&:hover,
							&.slick-current {
								border-color: transparent;
								background-image: url('/lg5-common-gp/images/components/gallery-3D-carmine.svg');
							}
						}
						// LGEGMC-3178 end
					}
					img { 
						max-width:100%;
						display:inline-block;
					}
				}
				.pdp-pagination {
					display:none;
					@include screen(custom, max, $max-sm) {
						display:block;
						position:absolute;
						top:-31px;
						width:270px;
						li {
							display:none;
							font-size:14px;
							color:$color-dimgray;
							&.slick-active {
								display:block;
							}
						} 
					}
				}
				.pdp-visual-pagination {
					position: absolute;
					bottom: 86px;
					left: 50%; transform: translatex(-50%);
					font-size:14px;
					line-height:20px;
					color:$color-dimgray;
					@include screen(custom, min, $min-md) {
						display:none;
					}
				}
				.control {
					// 20200422 START 이상현 - rtl국가에서 pdp layer popup의 zoom 버튼 위치 오류 수정
					position:absolute;
					top:50%;
					width:30px;
					margin-top:-45px;
					@include screen(custom, min, $min-md) {
						right:30px;
						padding-left:1px;
					}
					@include screen(custom, max, $max-sm) {
						right:auto;
						left:50%;
						top:30px;
						width:97px;
						height:40px;
						margin:0 0 0 -52px;
					}
					[dir="rtl"] & {
						@include screen(custom, min, $min-md) {
							right:auto;
							left:30px;
							padding-left:0;
							padding-right:-1px;
						}
						@include screen(custom, max, $max-sm) {
							left:auto;
							right:50%;
							margin:0 -52px 0 0;
						}
					}
					// 20200422 END
					&:before{
						content:"";
						position:absolute; 
						top:50%;
						left:0;
						width:30px;
						margin-top:-9px;
						border-bottom:1px solid $line-gray;
						@include screen(custom, max, $max-sm) {
							top:0;
							left:50%;
							width:auto;
							height:40px;
							border-right:1px solid $line-gray;
							border-bottom:none;
							margin:0 0 0 2px;
							[dir="rtl"] & {margin:0 0 0 -2px;}
						}
					}
					button {
						touch-action: manipulation;
						cursor:pointer;
					}
					.plus{
						// 20200421 START 이상현 - rtl국가에서 pdp layer popup의 zoom 버튼의 텍스트 노출 오류 수정
						overflow:hidden;
						color:transparent;
						// 20200421 END
						display:block;
						width:28px; 
						height:28px;
						margin-bottom:35px;
						border:none;
						background:url(/lg5-common-gp/images/common/icons/zoomin.svg) center no-repeat;
						text-indent:-9999em;
						[dir="rtl"] & {text-indent:9999em;}
						@include screen(custom, max, $max-sm) {
							margin-top:6px;
							margin-bottom:0;
							float:right;
							[dir="rtl"] & {float:left;}
						}
					}
					.minus{
						// 20200421 START 이상현 - rtl국가에서 pdp layer popup의 zoom 버튼의 텍스트 노출 오류 수정
						overflow:hidden;
						color:transparent;
						// 20200421 END
						display:block;
						width:28px; 
						height:28px;
						margin-bottom:17px;
						border:none;
						background:url(/lg5-common-gp/images/common/icons/zoomout.svg) center no-repeat;
						text-indent:-9999em;
						[dir="rtl"] & {text-indent:9999em;}
						@include screen(custom, max, $max-sm) {
							margin-top:6px;
							margin-bottom:0;
							margin-left:6px;
							float:left;
							[dir="rtl"] & {
								float:right;
								margin-left:0;
								margin-right:6px;
							}
						}
					}
					
				}

				img.lazyload {opacity:1;}
				//LGEGMC-1851 START
				.modal-messagecode{
					font-size: 14px;
					padding-left: 156px;
					display: inline-block;
					vertical-align: top;
					[dir="rtl"] & {padding-right: 156px;
						@include screen(custom, max, $max-sm){
							text-align: right;
							padding-right: 10px;
							padding-left: 0;
						}
					}
					@include screen(custom, max, $max-sm) {
						text-align: left;
						display: inline-block;
						width: calc(100% - 50px);
						vertical-align: top;
						padding-top: 5px;
						padding-left: 10px;
						line-height: 16px;
					}
				}
				//LGEGMC-1851 END
			}
		}
	}
	/* LGEGMC-849 20201103 add / LGEGMC-605 20201112 START */
	//LGEGMC-1632 add Ar
	//LGEGMC-3178 add modal3603D
	&#modal3603D,
	&#modalAr,
	&#modalVr,
	&#modal360 {
		z-index: 25001;
		.modal-dialog {			
			&.modal-pdp-gallery {
				height: 700px;
				@include screen(custom, max, 1069) {
					position: absolute;
				}
				.modal-content {
					padding:45px 0 12px;
					height: 100%;
					.item{
						height: calc(100% - 42px);
						.iframe-area{
							height: 100%;
						}
					}
				}
				.modal-body {
					padding: 0;
					height: auto;
					.btn-area {
						margin-top: 8px;
						padding-right: 8px;
						height: 40px;
						&.right{
							text-align: right;
						}
						[dir="rtl"] & {
							padding-left: 8px;
							padding-right: 0;
							&.right{
								text-align: left;
							}
						}
					}
					.full {
						display: inline-block;
						width: 40px;
						height: 40px;
						background:url('/lg5-common-gp/images/components/full.svg') no-repeat center;
						overflow: hidden;
						white-space: nowrap;
						text-indent: 200%;
					}
				}
				@include screen(custom, max, $max-sm) {
					height: 100%;
					.modal-content {
						height: 100%;
						.item{
							height: calc(100% - 42px);
							.iframe-area{
								height: 100%;
							}
						}
					}
				}
			}
		}
	}
	/* //LGEGMC-849 20201103 add / LGEGMC-605 20201112 END */
	
	&.loaded-iframe {
		.control {
			display: none !important;
		}
	}
}