.modal {
	&#modal_pdp_detail_gallery_improve{
		background: $bg-white;
		z-index: 25001 !important;
		.modal-dialog {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: 0 0;
			transform: translate(0,0);
			iframe {
				width:100%;height:100%;border:0;
			}
			.modal-close{
				top:40px;
				right: 40px;
				@include screen(custom, max, 640) {
					top: 16px;
					right: 16px;
				}
				[dir="rtl"] &{
					right:auto;
					left: 40px;
					@include screen(custom, max, 640) {
						right:auto;
						left: 16px;
					}
				}
			}
			.modal-body {
				padding: 40px 100px 40px;
				@extend %clearfix;
				touch-action: manipulation;
				position:relative;
				height: 100vh;
				background: $bg-white;				
				@include screen(custom, max, $max-sm) {
					padding: 60px 16px 100px;
					margin-bottom: 0;
				}
				&.append-target {
					padding-top:65px;
					padding-bottom:30px;
					@include screen(custom, max, $max-sm) {
						padding:50px 0 0;
					}
				}
				.default{
					position:relative;
					max-width: 1200px;
					width: 100%;
					max-height: 796px;
					height:100%;
					margin: 0 auto;
					ul{
						opacity: 0;
						&.slick-initialized{
							opacity: 1;
						}
					}
					.slick-list{
						height: 100%;
						.slick-track{
							height: 100%;
						}						
					}
					.item {
						&.image, &.video{
							width: 100%;
							height: 100%;
						}
					}
					.zoom-area {
						width:100%;
						height:100%;
						cursor: url('/lg5-common-gp/images/components/zoomin_cursor.png'), url('/lg5-common-gp/images/components/zoomin_cursor.cur'), pointer;
						&.zoomout {
							cursor: url('/lg5-common-gp/images/components/zoomout_cursor.png'), url('/lg5-common-gp/images/components/zoomout_cursor.cur'), pointer;
						}
					}
					img { 
						position: absolute;
						top:0;bottom:0;left:0;right:0;
						margin: auto;
						max-width:100%;
						max-height:100%;
						transition: transform 200ms ease-in-out 0s;
						&.pc{
							display:block;
						}
						&.mobile{
							display:none;
						}
						@include screen(custom, max, $max-sm) {	
							transition: none;
							&.pc{
								display:none;	
							}
							&.mobile{
								display:block;
								margin: 0 auto;
							}
						}
					}
					@include screen(custom, max,  $max-md) {
						max-height: 600px;
					}
					@include screen(custom, max,  $max-sm) {
						max-height: 540px;
					}
					@include screen(custom, max, 640) {
						max-height: calc(100% - 230px);
					}
				}
				.iframe-area {
					width: 100%;
					height: 100%;
					position: relative;
					iframe{
						position: absolute;
						top: 0;
						left: 0;
					}
					@include screen(custom, max, 640) {
						height: calc(100% - 40px);
						&:only-child{
							height: 100%;
						}
					}
				}
				.thumbnail{
					margin: 20px auto 0;
					text-align: center;
					@include screen(custom, max, 480) {
						margin: 10px auto 0;
					}
					ul{
						position: relative;
						display: inline-block;
						vertical-align: top;
						max-width: 965px;
						width: 100%;
						padding: 0 40px;
						opacity: 0;
						&.slick-initialized{
							opacity: 1;
						}
						@include screen(custom, max, $max-sm) {
							max-width: 750px;
						}
						@include screen(custom, max, 480) {
							padding: 0 0;
							.slick-list{
								padding: 0 20px;
							}
						}
					}
					.item{
						width: 100px;
						height: 100px;
						position: relative;
						margin: 0 6px;
						@include screen(custom, max, 480) {
							height: auto;
							&.video{
								height: 100px;
							}
						}
						&.slick-slide{
							&.slick-current{
								a{
									border: 2px solid $color-carmine;
								}
							}
						}
						img{
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
						&.video{
							a:before{
								width: 30px;
								height: 30px;
								margin: -15px 0 0 -15px;
							}
						}
						a{
							border: 1px solid $line-gray;
							border-radius: 6px;
							overflow: hidden;
							width: 100%;
							height: 100%;
							display: block;
							&:focus,&:hover{
								border: 2px solid $color-carmine;
							}
						}
					}
				}
				
				.gallery-view-control{
					display: flex;
					max-width: 1200px;
					width: 100%;
					margin: 0 auto 20px;
					justify-content: space-between;
					.gallery-view-etc{
						ul{
							display: flex;
							justify-content: center;
							align-items: center;
							height: 50px;
							background: $bg-whitesmoke;
							border: 1px solid $line-gray;
							border-radius: 50px;
							padding: 0 12px;
							li{
								padding: 0 10px;
								position: relative;
								height: 100%;
								display: inline-flex;
								align-items: center;
								&:after{
									content: '';
									left: 0;
									top: 0;
									bottom: 0;
									position: absolute;
									width: 1px;
									background: #e4e4e4;
									display: block;
									// [Start] LGEIN-1131
									[dir="rtl"] & {
										left: auto;
										right: 0;
									}
									// [End] LGEIN-1131
								}
								&:first-child{
									&:after{
										content: none !important;
									}
								}
								// [Start] LGEIN-1131
								a {
									position: relative;
									display: block;
									width: 32px;
									height: 32px;
									text-indent: -99999px;
									border:0;
									background-repeat: no-repeat;
									background-position: center center;
									background-size: 100%;
									&.image-list {
										background-image: url('/lg5-common-gp/images/components/gallery_image.svg');
										&.active,
										&:hover,
										&:focus {
											background-image: url('/lg5-common-gp/images/components/gallery_image_carmine.svg')
										}
									}
									&.video-list {
										background-image: url('/lg5-common-gp/images/components/gallery_video.svg');
										&.active,
										&:hover,
										&:focus {
											background-image: url('/lg5-common-gp/images/components/gallery_video_carmine.svg')
										}
									}
									&.view360 {
										background-image: url('/lg5-common-gp/images/components/gallery_360vr.svg');
										&.active,
										&:hover,
										&:focus {
											background-image: url('/lg5-common-gp/images/components/gallery_360vr_carmine.svg')
										}
									}
									&.viewVr {
										background-image: url('/lg5-common-gp/images/components/gallery_vr.svg');
										&.active,
										&:hover,
										&:focus {
											background-image: url('/lg5-common-gp/images/components/gallery_vr_carmine.svg')
										}
									}
									&.viewAr {
										background-image: url('/lg5-common-gp/images/components/gallery_ar.svg');
										&.active,
										&:hover,
										&:focus {
											background-image: url('/lg5-common-gp/images/components/gallery_ar_carmine.svg')
										}
									}
									&.view360_v2 {
										background-image: url('/lg5-common-gp/images/components/gallery_3d.svg');
										&.active,
										&:hover,
										&:focus {
											background-image: url('/lg5-common-gp/images/components/gallery_3d_carmine.svg');
										}
									}
									[data-countrycode="in"] & {
										width: 40px;
										height: 40px;
										@include screen(custom, max, $max-xs) {
											width: 32px;
											height: 32px;
										}
									}
								}
								// [End] LGEIN-1131
							}
						}
					}
					.control{
						display: none;
						[dir="rtl"] &{
							direction: ltr;
						}
						button {
							touch-action: manipulation;
							cursor:pointer;
							width: 60px;
							height: 50px;
							text-indent: -99999px;
							display: inline-block;
							border:none;
							overflow:hidden;
							border: 1px solid $line-gray;
							margin: 0 -3px;
							&:focus{
								position: relative;
								z-index: 1;
							}
						}
						.minus{						
							background:$bg-whitesmoke url(/lg5-common-gp/images/common/icons/zoomout.svg) center no-repeat;
							border-radius: 30px 0 0 30px;
						}
						.plus{
							background:$bg-whitesmoke url(/lg5-common-gp/images/common/icons/zoomin.svg) center no-repeat;
							border-radius: 0 30px 30px 0;
						}
					}
					@include screen(custom, max,  $max-sm) {
						margin: 0 0;
						.gallery-view-etc{
							position: absolute;
							bottom: 16px;
							// [Start] LGEIN-1131
							left: 50%;
							transform: translateX(-50%);
							// [End] LGEIN-1131
							text-align: center;
							ul{
								display: inline-flex;
								margin: 0 auto;
								height: 38px;
								li{
									margin: 0 0;
									padding: 0 6px;
								}
							}
						}
						.control{
							position: absolute;
							top: 16px;
							left: 0;
							right: 0;
							text-align: center;
							button {
								width: 55px;
								height: 30px;
								background-size: 16px !important;
							}
						}
					}
				}
				img.lazyload {opacity:1;}
				.pagingInfo{
					text-align: center;
					font-size: 14px;
					color: $color-dimgray;
					margin: 10px auto;
					@include screen(custom, max,  $max-sm) {
						margin: 3px auto;
					}
				}
				.tab-area{
					display: none;
					position: relative;
					margin: 0 auto;
					max-width: 1200px;
					width: 100%;
					height: calc(100% - 135px);
					&.active{
						display: block;
					}
					@media screen and (min-width: 992px) and (max-height: 1080px){
							height: calc(100% - 230px);
					}
					@include screen(custom, max, 640) {
						margin: 0 -16px;
						width: auto;
						height: 100%;
						overflow-y: auto;
					}
				}				
				.modal-messagecode{
					font-size: 14px;
					display: inline-block;
					vertical-align: top;
					@include screen(custom, max, $max-sm) {
						text-align: left;
						display: inline-block;
						vertical-align: top;
						padding-top: 5px;
						line-height: 16px;
						margin:0 16px;
					}
				}
			}
		}
	}
}