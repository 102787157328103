@charset 'UTF-8';
// 컴포넌트에 사용하는 custom 스타일을 정의하는 기본 값 important로 정의
//
.custom-style{	
	//custom style 용 important
	$imp-font-bold: 'LG Smart Bold', sans-serif;
	$imp-font-light: 'LG Smart Light', sans-serif;
	$imp-font-regular: 'LG Smart Regular', sans-serif;
	$imp-font-semibold: 'LG Smart SemiBold', sans-serif;
	@mixin font-family($font, $sytle:normal) {
		@if ($font == $imp-font-regular) {
			font-weight:400 !important;
		} @else if ($font == $imp-font-light) {
			font-weight:300 !important;
		} @else if ($font == $imp-font-semibold) {
			font-weight:600 !important;
		} @else if ($font == $imp-font-bold) {
			font-weight:700 !important;
		} 
	}
	// text-align
	&.align-left {
		text-align:left !important;
	}
	&.align-right {
		text-align:right !important;
	}
	&.align-center {
		text-align:center !important;
	}	

	// font opion style
	&.font-light {
		@include font-family($imp-font-light);
	}
	&.font-regular {
		@include font-family($imp-font-regular);
	}
	&.font-semibold{
		@include font-family($imp-font-semibold);
	}
	&.font-bold {
		@include font-family($imp-font-bold);
	}
	&.font-dec-underline {
		text-decoration:underline !important;
	}
	&.font-style-italic {
		font-style:italic !important;
	}
	&.font-style-normal{
		font-style:normal !important;
	}
	
	// font color 
	&.imp-font-carmine{
		color: $color-carmine !important;
	}
	&.imp-font-black{
		color: $color-black !important;
	}
	&.imp-font-nightrider{
		color: $color-nightrider !important;
	}
	&.imp-font-dimgray{
		color: $color-dimgray !important;
	}
	&.imp-font-gray{
		color: $color-gray !important;
	}
	&.imp-font-white{
		color: $color-white !important;
	}

	//
	&.ty-b{
		display: block !important;
	}
	&.ty-ib{
		display: inline-block !important;
	}

	// pc 용 custom font-size
	@include screen(custom, min, $min-md) {
		&.fz-pc-38{
			font-size: 38px !important;
		}
		&.fz-pc-36{
			font-size: 36px !important;
		}
		&.fz-pc-34{
			font-size: 34px !important;
		}
		&.fz-pc-32{
			font-size: 32px !important;
		}
		&.fz-pc-30{
			font-size: 30px !important;
		}
		&.fz-pc-28{
			font-size: 28px !important;
		}
		&.fz-pc-26{
			font-size: 26px !important;
		}
		&.fz-pc-24{
			font-size: 24px !important;
		}
		&.fz-pc-22{
			font-size: 22px !important;
		}
		&.fz-pc-20{
			font-size: 20px !important;
		}
		&.fz-pc-18{
			font-size: 18px !important;
		}
		&.fz-pc-16{
			font-size: 16px !important;
		}
		&.fz-pc-14{
			font-size: 14px !important;
		}
	}
	
	// mo 용 custom font-size
	@include screen(custom, max, $max-sm) {
		&.fz-mo-30{
			font-size: 30px !important;
		}
		&.fz-mo-28{
			font-size: 28px !important;
		}
		&.fz-mo-26{
			font-size: 26px !important;
		}
		&.fz-mo-24{
			font-size: 24px !important;
		}
		&.fz-mo-22{
			font-size: 22px !important;
		}
		&.fz-mo-20{
			font-size: 20px !important;
		}
		&.fz-mo-18{
			font-size: 18px !important;
		}
		&.fz-mo-16{
			font-size: 16px !important;
		}
		&.fz-mo-14{
			font-size: 14px !important;
		}
		&.fz-mo-12{
			font-size: 12px !important;
		}
		&.fz-mo-10{
			font-size: 10px !important;
		}
	}
}

