// epromotor-widget-area 에 따른 chatbot msg 작업 
// DE 국가 기준으로 초기 제작 되었습니다.
// _epromoter-index 와 _btn-fix-bottom 작업 관계가 있으니 같이 체크 부탁드립니다
.chat-msg{
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 99999;
    display: none;
    pointer-events: none;
    &.cp11{
        @include screen(custom, max, $max-sm) {
            bottom: 70px !important;
        }
    }
    &.cp22{
        bottom: 135px !important;
        @include screen(custom, max, $max-sm) {
            bottom: 71px !important;
        }
    }
    &.cp11.cp22{
        @include screen(custom, max, $max-sm) {
            bottom: 130px !important;
        }
    }
    &.ready{
        display: flex;
        flex-flow: column;
        align-content: flex-end;
        align-items: flex-end;
    }
    @include screen(custom, max, $max-sm) {
        bottom: 20px!important;
        right: 12px !important;
        left: 12px!important;
        &.ready{
            align-items: center;
        }
    }
    p,ul,ol,li,dl,dt,dd{
        margin: 0 0;
        padding: 0 0;
    }
    .chat-msg-area{
        position: relative;
        padding: 0 0;
        text-align: center;
        border-radius: 28px;
        opacity: 0;
        overflow: hidden;
        margin-top: 5px;
        height: 56px;
        width: 56px;
        max-width: 56px;
        min-width: 56px;
        background-color: #d42d5b;
        padding: 19px 38px 19px 24px;
        transition: all 0.3s;
        @include screen(custom, max, $max-sm) {
            padding: 18px 38px 18px 24px;
        }
        &.active{
            height: auto;
            text-align: center;
            width: 100%;
            max-width: 370px;
            min-height: 56px;
            min-width: 56px;
            opacity: 1;
            pointer-events: auto;
            .chat-msg-box{
                display: block !important;
                min-width: 275px;
            }
            .msg-close{
                display: block !important;
            }
            @include screen(custom, max, $max-sm) {
                max-width: 100vw;
            }
        }
        .msg-close{
            width: 30px;
            height: 30px;
            display: none;
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-15px);
            text-indent: -99999px;
            border: 0;
            padding: 0 0;
            background: url('/lg5-common-gp/images/common/icons/popover-close-w.svg') no-repeat center center;
            background-size: 13px 13px;
            z-index: 3;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
        }
        .chat-msg-box{
            display: none;
            min-width: 0;
            a,p{
                line-height: 1.3em;
                font-size: 14px;
                color: $color-white;
            }
        }
    }
    // LGCOMPH-46 start
    .epromotor-msg {
        display: none;
        position: absolute;
        top: 50%;
        right: 61px;
        transform: translateY(-50%);
        width: max-content;
        max-width: 175px;
        border-radius: 10px;
        border: 1px solid $line-gray;
        background-color: $bg-white;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        @include screen(custom, max, $max-sm) {
            left: 61px;
            right: auto;
        }
        &.active {
            display: block;
            pointer-events: auto;
        }
        .epromotor-msg-box {
            padding: 12px 31px 12px 15px;
            font-size: 13px;
            line-height: 18px;
            color: $color-nightrider;
        }
        .epromotor-msg-close {
            position: absolute;
            right: 3px;
            top: 3px;
            width: 24px;
            height: 24px;
            background: transparent url(/lg5-common-gp/images/common/icons/cookie-popover-close.svg) no-repeat 50% 50%;
            background-size: 8px;
            border: none;
            cursor: pointer;
        }
    }
    // LGCOMPH-46 end
}

// menu open bottom fixbtn
body{
	&.m-menu-open, &.band-scroll, &.modal-open{
		.chat-msg{
			z-index:2 !important;
		}
	}
    &.compare-sticky-showing{
        .chat-msg{
            display: none !important;
        }
        &.compare-sticky-min{
            .chat-msg{
                display: flex !important;
            }
        }
    }
    .epromotor-chat-area.active ~ .chat-msg {
        z-index: 0;
    }
}
